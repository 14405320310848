<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Events</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Add Event</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Event Name</label>
            <input
              v-model="event.name"
              type="text"
              class="form-control"
              placeholder="Enter Event Name"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Event Month</label>
            <input
              v-model="event.month"
              type="month"
              class="form-control"
              placeholder="Enter Service Name"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Event Description</label>
            <textarea
              v-model="event.description"
              type="text"
              class="form-control"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="row">
            <div class="col image-containers">
              <div>
                <img :src="image1Url" class="img-fluid w-25 d-inline-block" />
              </div>
              <div class="form-group d-inline-block">
                <label for="exampleFormControlFile1">Featured Image</label>
                <input
                  @change="setImageOnePreview"
                  ref="image1Input"
                  type="file"
                  class="form-control-file"
                  id="exampleFormControlFile1"
                />
              </div>
            </div>
          </div>

          <div class="images-container">
            <h5>Event Images</h5>

            <div class="image-input-container my-5">
              <div v-for="(photo, i) in event.photos" :key="i">
                <div>
                  <img
                    :src="photoUrls[i]"
                    class="img-fluid w-25 d-inline-block w-100"
                  />
                </div>
                <div class="form-group d-inline-block w-100">
                  <label for="exampleFormControlFile1">Photo {{ i + 1 }}</label>
                  <input
                    @change="setPhotoPreview(i)"
                    :ref="`photo${i}Input`"
                    type="file"
                    class="form-control-file"
                    id="exampleFormControlFile1"
                  />
                  <textarea
                    v-model="event.photos[i].caption"
                    type="text"
                    class="form-control mt-2"
                    placeholder="Caption..."
                  />
                  <button
                    @click.prevent="removePhotoInput(i)"
                    class="btn btn-danger btn-block mt-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div @click="addPhotoInput" class="add-photo-button">
                <img src="@/assets/add-button.svg" />
              </div>
            </div>
          </div>

          <button @click.prevent="saveEvent" class="btn btn-block btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "AddEvent",
  data() {
    return {
      image1Url: "/placeholder.png",
      photoUrls: ["/placeholder.png"],
      event: {
        name: "",
        description: "",
        month: "",
        featuredImage: "",
        photos: [{ photo: "", caption: "" }],
      },
    };
  },
  methods: {
    async getEvent() {
      const response = await axios.get(
        `/api/v1/events/${this.$route.params.id}`
      );

      this.event = response.data.event;
      this.event.month = this.event.monthFormattedHtml;
      this.image1Url = `/uploads/${this.event.featuredImage}`;
      this.photoUrls = response.data.event.photos.map(
        (p) => `/uploads/${p.photo}`
      );
    },
    async setImageOnePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.image1Url = fr.result;
      };
      const src = fr.readAsDataURL(this.$refs.image1Input.files[0]);
    },
    async setPhotoPreview(i) {
      const fr = new FileReader();
      fr.onload = () => {
        this.photoUrls[i] = fr.result;
      };
      const src = fr.readAsDataURL(this.$refs[`photo${i}Input`].files[0]);
    },
    addPhotoInput() {
      this.event.photos.push({ photo: "", caption: "" });
      this.photoUrls.push("/placeholder.png");
    },
    removePhotoInput(i) {
      this.event.photos.splice(i, 1);
      this.photoUrls.splice(i, 1);
    },
    async saveEvent() {
      try {
        if (this.$refs.image1Input.files.length) {
          const featuredImage = await this.$store.dispatch(
            "uploadImage",
            this.$refs.image1Input.files[0]
          );

          this.event.featuredImage = featuredImage;
        }

        for (let i = 0; i < this.event.photos.length; i++) {
          if (this.$refs[`photo${i}Input`].files.length) {
            this.event.photos[i].photo = await this.$store.dispatch(
              "uploadImage",
              this.$refs[`photo${i}Input`].files[0]
            );
          }
        }

        const res = await axios.patch(
          "/api/v1/events/" + this.$route.params.id,
          this.event,
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 200) {
          this.$router.push("/events");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
  },
  mounted() {
    this.getEvent();
  },
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
.image-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}
.image-containers {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add-photo-button {
  cursor: pointer;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-photo-button > img {
  opacity: 0.2;
  width: 20%;
  height: 20%;
}
</style>